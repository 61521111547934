.nav_area {
  position: sticky;
  top: 0;
  z-index: 999;
  transition: background-color 0.4s ease, box-shadow 0.5s ease;
  background: #002E5D;
}

.nav_area_home {
background:transparent;
}

.nav_area_common {
  background-color: #002E5D;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_container_right .nav_container_right_inner,
.nav_container_left,
.nav_container_right {
  display: flex;
  align-items: center;
  gap: 25px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;

  
}
.nav_container_right .nav_container_right_innermenu a{padding:5px 0}
.nav_container_right {
  gap: 25px;
}
.withsubmenunav {
  position: relative;
  cursor: pointer;
}
.nav_subulweb {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 50px;
  list-style: none;
  background-color: #fff;
  min-width:200px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px #0000001A;
  transition: 0.8s;
  padding:10px 20px;
  list-style: none;
}
.nav_subulweb:before{position: absolute;content:"";  top: -10px;
  left: 20px; /* Adjust based on your design */
  width: 16px;
  height: 13px;
  background: white;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);}
  .drop_submenu{position: absolute;
    display: none;
    left:225px;
    
    top: 0;
    list-style: none;
    background-color: #fff;
    min-width:320px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #0000001A;
    transition: 0.8s;
    padding:10px 20px;
    transition: all 0.3s ease-in-out;
    list-style: none;}
    .drop_submenu:before{position: absolute;content:"";  top:50%;margin-top:-8px;
      left: -14px;
      width: 16px;
      height: 13px;
      background: white;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1); transform: rotate(-90deg);}
      .list_submenu.submenu_open .drop_submenu {
        display: block;
      }
/* .withsubmenunav:hover .nav_subulweb {
  opacity: 1;
  top: 35px;
  visibility: visible;
} */
.nav_subul li .navbar_desktop span{display: flex;justify-content: space-between;align-items: center;}
.withsubmenunav.selectedmenu .nav_subulweb{opacity: 1;
  top: 35px;
  visibility: visible;}

.mobile_subnav {
  list-style: none;
  padding:10px 20px 0 0;
}
.nav_subul li {
  padding: 10px 0;
}
.nav_subul li a,.nav_subul li.list_submenu span {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}
.withsubmenunav .mainspan {
  position: relative;
  padding-right: 14px;
}
.withsubmenunav .mainspan:after {
  position: absolute;
  top: 50%;
  right: 0;
  background: no-repeat bottom center url('../../Helper/images/header_menudrop.svg');
  width: 10px;
  height: 5px;
  content: "";
}
.hidesubmenu {
  display: none;
}
.nav_subul li.header-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}
.ResourcesDropdown {
  display: flex;
  gap: 30px;
}
.currentPage {
  color: #fff;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-Color, #002E5D);
}
.nav_subul li .navbar_desktop span.currentPage {
  border: none;
  color: rgba(0, 46, 93, 1);font-weight: 600;
}
.nav_area_blog .withsubmenunav .currentPage {
  border: none;
  color: rgba(0, 46, 93, 1);
}
.nav_area_blog .withsubmenunav .mainspan.currentPage {color:#fff;}
.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  right: 12px;
  top: 15px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-burger-bars-hover {
  background: #a90000;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding: 26px 5px;
}
.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.nav_container_right .bm-item {
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 18px;
  font-weight: 500;
}

.bm-burger-button .bm-burger-bars,
.bm-cross-button .bm-cross,
.bm-burger-button {
  display: none;
}

.navbar_btn {
  display: flex;
  gap: 20px;
}

.nav_area.onscroll_bg {
  background-color: #002E5D;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.nav_area_blog {
  box-shadow: 0 0 1px 0px rgb(0 0 0 / 50%);
}

.menuburgerlist .bm-menu-wrap {
width:100% !important
}
.menuburgerlist .bm-item.listmenu .burgerhead,.dropdownburger .listmenu .burgerhead{
  display: flex !important;justify-content: space-between;align-items: center;
  padding:0 10px 10px 0;margin-bottom: 15px;

}
.dropdownburger .listmenu .burgerhead.burgerheadsubmenu{margin-bottom: 0;}
.dropdownburger .listmenu .burgerhead{
padding-top: 18px;
padding: 0 0 15px 0;
}
.menuburgerlist .bm-menu {
  padding-top:15px
}
.menuburgerlist .bm-item.listmenu .burgerhead svg,.dropdownburger .listmenu .burgerhead svg{
color: #002E5D;
width:30px;
height:30px
}
.dropdownburger .listmenu .burgerhead svg{margin-right: 10px;}
.menuburgerlist .bm-item.listmenu .nav_container_right{
  display: flex;
  flex-direction: column;
  gap:0;
  align-items: start;color:#000;font-weight:400;font-size: 15px;
} 
.menuburgerlist .bm-item.listmenu .navbar_mobile.withsubmenunav{
width: 95%;
display: inline-block;
}
.menuburgerlist .bm-item.listmenu .navbar_mobile.withsubmenunav span{
  width:100%;
display: inline-block;
 
}
.menuburgerlist .bm-item.listmenu .withsubmenunav .mainspan:after {
  position: absolute;
  top: 8px;
  right: 0;
  background: no-repeat bottom center url('../../Helper/images/new/header_menudrop_burger.svg');
  width: 12px;
  height: 7px;
  content: "";
  left: 100%;
  transform: rotate(268deg);
  /* width: 50px; */
  /* height: 50px; */
}
.bm-item-listout .bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;padding:0 5px;
}
.bm-item-listout .bm-item-list button{
  width:100%;
  margin-bottom: 0;
}
.dropdownburger .MuiPaper-root.MuiPaper-elevation{
  width:100%;
  padding: 0.8rem;
}
.bm-item.dropdownburger .MuiPaper-root.MuiPaper-elevation{
  width:100%;
  padding: 0.8rem;
}
.bm-item.dropdownburger .nav_subul.mobile_subnav{padding-top: 0;}
.bm-item.dropdownburger .title_li{
font-size: 20px;
font-weight: 600;
}
.Sidedrawer{
  display: none;
}
.nav_logo{margin:5px 0}
.navbar_mobile .currentPage{color:#002E5D}
.droparrow-icon{margin-left:10px}
@media only screen and (max-width: 925px) {
  .bm-burger-button {
    display: block;
  }

  .navbar_desktop,
  .navbar_desktop .navbar_btn {
    display: none;
  }

  .navbar_mobile {
    margin-bottom: 15px;
  }
  .mobile_subnav .navbar_mobile {
    margin-bottom: 0;font-size: 15px;
  }

  .navbar_btn {
    flex-direction: column;
  }

  .nav_container {
    padding: 13px 0 13px 0;
  }

  .nav_logo {
    height: 35px;
    width: auto;
  }

  .mobile_nav_btn {
    padding: 5px 10px;
  }

  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 22px;
  }
}

@media only screen and (max-width: 367px) {
  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 17px;
  }
 
}
