.footer_area {
  /* background: var(--primary-Color1, #002E5D); */
  background-color: #002E5D;
}
.footer_container {
  width: 100%;
  color: #eaecf0;
  padding: 60px 0px 25px 0px;
}

.footer_content_container {
  display: flex;
  padding-bottom: 64px;
  flex-wrap: wrap;
  gap: 40px;position: relative;
}

.footer_heading {
  color: var(--white-text-color, #fff);
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 16px; margin-bottom: 20px;
}

.footer_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.bold-text.footer_text {
  font-weight: 600;
}
.bottom-space.footer_text {
  padding-bottom: 3px;
}
.footer_link {
  cursor: pointer;
}

.footer_nav_area {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 233px;
}

.footer_demo_text {
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
}

.footer_inner_container {
  max-width: 270px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.footer_demo_btn_container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.footer_contact_area {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 322px;
}
.footer_contact_area > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer_contact_area .footer_contact_area_inner {
  gap: 24px;
}

.social_media_container {
  display: flex;
  gap: 15px;
}



.social_icons {
  display: flex;
  gap: 10px;
}

.footer_contact_area > div.footer_addresscontainer{gap:15px}
.footer_contact_area > div.footer_contact_logosec{display: flex;gap:20px;flex-direction: column;}
.footer_contact_logosec p{font-weight: 400;font-size: 16px;line-height: 25px;color:#fff;}
.address_hd{font-size: 16px;line-height: 25px;color:#fff;display: flex;justify-content: space-between;cursor: pointer;}
.address_hd span{display: flex;gap:12px;align-items: center;font-weight:500;}
.address_count{display: flex;gap:10px;font-weight: 400;font-size: 16px;line-height: 25px;}
.address_count img{margin-top:2px}
.footer_addresslist{display: flex;gap:15px;flex-direction: column;}
.footer_addresslistmid{display: flex;gap:10px;flex-direction: column;}
.footer_listmenuul{list-style: none;margin:0;padding:0;display: flex;flex-direction: column;gap: 10px;}
.footer_listmenuul li{list-style: none;margin:0;padding:0}
.footer_listmenusecond{display: flex;gap:50px;flex-direction: column;}
.footer_listmenu{width: 20%;}
.footer_listmenuulsubmenu{padding-left: 20px;}
.footer_texttoggle{display: flex;justify-content: space-between;align-items: center;cursor: pointer;}
.footer_listmenuulsubmenu{margin-top:15px}
.footer_contact_area > div.footer_addresscontainer .footer_heading{margin: 0;}
.footer_form .contactform_container{display: flex;gap: 15px;flex-direction: column;background: transparent;padding: 0;border: 0;}
.footer_form .contactform_container .input-container>input,.footer_form .contactform_container .input-container>textarea{color:#fff;background:0 0;font-size:15px;font-weight:400;line-height:20px;padding:11px 12px;border:1px solid #fff;border-radius:5px;width:100%;font-family:inherit}
.footer_form .contactform_container .input-container>input::placeholder,.footer_form .contactform_container  .input-container>textarea::placeholder{
color:rgba(255, 255, 255, 0.8);
}
.footer_form .error-message {color: red;font-size: 13px;font-weight: 400; padding-top: 5px;}
.footer_listform{width:23%;position: relative;}
.faq_logsc1{margin-top:20px;width:90px;height:90px;border-radius: 100%; border:8px solid #fff;align-items: center;justify-content: center;display: flex;	animation-name: faq-pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;position: absolute;right:0;bottom:20px}
  .faq_logsc1 img{margin-top:10px}
@keyframes faq-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}
.footer_form .react-tel-input .form-control{width:100%;background: transparent;height:44px;padding-left: 60px;color:#fff; border:1px solid #fff!important;}
.footer_form .react-tel-input .flag-dropdown{background: transparent;border:1px solid #fff!important;border-radius: 0!important;}
.footer_form .react-tel-input .selected-flag{background: transparent;width:53px}
.footer_form .react-tel-input .selected-flag .arrow {
  left: 24px; background:url(../../Helper/images/new/lang_droparrowwhite.svg) no-repeat;width:12px;height:6px;border:0;margin-top:-4px}
.footer_form .react-tel-input .selected-flag:hover,.footer_form .react-tel-input .selected-flag:focus{background: transparent;}
.footer_form .react-tel-input .country-list .country-name{color:#000;}
.footer_form iframe {transform: scale(0.7);transform-origin: 0 0;width:100%;height:212px}
.footer_form .btn-common-style{width:100%;text-align: center;color:rgba(0, 46, 93, 1)}
.footer_form .btn_common svg{display: none;}
.footer_form .not-loading.btn:hover:before,.footer_form .not-loading.btn:hover:after{display: none;}
.footer_cpyright{border-top: 1px solid rgba(255, 255, 255, 0.1); padding-top: 20px;display: flex;
  justify-content: space-between;}
.footer_cpyright p{font-size: 14px;font-weight: 400;line-height: 35px;}
.social_icons a.social_link{width:33px;height:33px;background:#FFFFFF;border-radius: 100%;display: flex;align-items: center;justify-content: center;}
.footer_form .contactform_container_row_bottom{width:100%!important;}
.footer_form .contactform_container_row_bottom >div {width:100%;}

@media only screen and (max-width: 1024px) {
.footer_contact_area{max-width: 45%;}
.footer_listmenu{width:45%;}
}
@media only screen and (max-width: 940px) {
  .footer_content_container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 568px) {
  .footer_demo_text {
    font-size: 25px;
  }
  .footer_container {
    padding: 24px 0px 24px 0px;
  }
  .footer_nav_area {
    max-width: fit-content;
    gap: 6px;
  }
  .footer_heading {
    margin-bottom: 10px;
  }
  .footer_content_container {
    padding-bottom: 36px;
  }
  .footer_inner_container {
    gap: 18px;
  }
  .footer_cpyright {
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}
}
@media only screen and (max-width: 567px) {
  .footer_contact_area{max-width: 100%;}
  .footer_listmenu{width:100%;}
  .footer_content_container {padding-bottom: 150px;}
  .footer_content_container{gap:20px}
  .footer_container{padding:20px 0 0}
  .footer_listmenusecond{gap:20px}
  .footer_cpyright{padding:10px 0}
}
@media only screen and (max-width: 480px) {
.common_outlinebtn, .home_linksbtn .btn-secondary, .home_linksbtn .btn-primary
{width:100%;text-align: center;}
.hm_bannerlft{text-align: center;}
.banner_subhd {font-size: 16px;text-align: center;width: 100%;display: flex;justify-items: center;
  align-items: center;justify-content: center;}
 
}
@media only screen and (max-width: 435px) {
  .footer_demo_btn_container {
    flex-direction: column;
    gap: 13px;
  }

}
