:root {
  --primary-Color: #002E5D;
  --text-primary-Color: #101828;
  --text-secondary-Color: #121330;
  --white-text-color: #fff;
  --neutral-800: #002E5D;
  --neutral-600: #6F6C90;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-Color: #002E5D;
  }
}
@import url("https://fonts.cdnfonts.com/css/graphik-trial");

body {
  background-color: #fff;
  color: var(--text-primary-Color, #101828);
  font-family: 'Graphik Trial', sans-serif;
  font-display: swap;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:after,
:before {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.demo-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  overflow: hidden;
}

.demo-close-btn {
  position: absolute;
  right: 19px;
  top: 5px;
  z-index: 1;
}

.demo-popup {
  min-width: 1000px;
  height: 650px;
}
.txt_center{text-align: center;}
.blogbannerbg .banner_container1,.case_bannerbg .banner_container1{height:200px}
.blogbannerbg{background-color: #002E5D;}
.blogbannerbg .banner_inner{height:100%}
.blogbannerbg .banner_inner .banner_leftfullcenter{height:100%;justify-content: center;align-items: center;    display: flex;flex-direction: column;}
.bannerbutton_arrow{background: url(../Helper/images/new/bannerbutton_arrow.svg) no-repeat;width:14px;height:15px;display: inline-block;margin-left:5px;vertical-align: middle;}
.common_innerbannerimg{height:342px;position: relative;}
.common_innerbannerimg:before{background:rgba(0, 46, 93, 0.5);height:100%;position: absolute;left:0;top:0;content: "";width:100%}
.inner_bannercontainer,.banner_container_mids{height:100%;position: relative;}
.banner_container_mids{display: flex; align-items: center;justify-content: center;gap:5px;flex-direction: column;height:100%;padding:50px 0 15px 0;text-align: center;}
.bluebg_banner .banner_container_mids{height:185px;padding:15px 0;text-align: center;}
.inner_banner_heading{font-weight: 600;font-size: 30px;color:#FFFFFF;line-height: 36px;}
.inner_banner_text{font-weight: 500;font-size: 16px;color:#FFFFFF;line-height: 27px;}
.bluebg_banner .inner_banner_text{font-weight: 400;}
.common_breadcrumb{padding:25px 0}.common_breadcrumb ul{list-style:none;display:flex;align-items:center;padding:0;margin:0}.common_breadcrumb li{display:flex;align-items:center;font-weight:500;font-size:18px;line-height:19.8px}.common_breadcrumb li a{text-decoration:none;color:#222;transition:color .3s}.common_breadcrumb li a:hover{color:#222}.common_breadcrumb li+li::before{content:"/";margin:0 8px;color:#222}.breadcrumb-title{color:#222;font-weight:400}
.bluebg_banner{background:#002E5D;}
.bluebg_banner .container{min-height:185px}

.our_intemiddle, .wrk_plleft, .wrk_plrgt, .wrk_plrgtlist, .wrk_plrgtlistlft, .wrk_plrgtlistrgt{display:flex}
.right_arrowcontact1 {width: 14px;height: 15px;display: inline-block; margin-left: 10px;}
.common_outlinebtn:hover .right_arrowcontact,.right_arrowcontact1{background:url(../Helper/images/new/right_arrowcontact1.svg) no-repeat}
.wrk_plleft{padding:50px 0;position:relative;height:100%;justify-content:center;flex-direction:column;width:83%;display:flex;gap:15px}
.wrk_plheading{font-weight:600;color:#fff;font-size:30px;line-height:40px}
.wrk_plcontent{font-weight:400;color:#fff;font-size:18px;line-height:30px}.viewbtn{margin-top:10px}
.viewbtn .btn-primary{background:#fff;border-radius:50px;font-weight:500;font-size:16px;line-height:28px;color:#002e5d;padding:8px 20px;border:1px solid #fff;min-width:135px;display:inline-flex;gap:5px;align-items:center}
.viewbtn .btn-primary:hover {background: 0 0;color:#fff;border: 1px solid #fff;}
.viewbtn .btn-primary:hover .right_arrowcontact1{animation-name:bounceAlpha;animation-duration:1.4s;animation-delay:.2s;animation-iteration-count:infinite;animation-timing-function:linear;
  background:url(../Helper/images/new/right_arrowcontact1_white.svg) no-repeat}
.loader{position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);display:flex;justify-content:center;align-items:center;height:100vh;width:100vw;background-color:rgba(255,255,255,.5);z-index:9999}
@keyframes bounceAlpha{0%,55%{opacity:1;transform:translateX(0) scale(1)}25%{opacity:0;transform:translateX(10px) scale(.9)}26%{opacity:0;transform:translateX(-10px) scale(.9)}}.bounceAlpha{animation-name:bounceAlpha;animation-duration:1.4s;animation-iteration-count:infinite;animation-timing-function:linear}
.cta_bxouter{border-radius: 20px;background:#002E5D;}
.cta_bxinner{display: flex;min-height:350px;gap:20px;align-items: center;}
.cta_bxinnerleft,.home_ctaouter .cta_bxinnerleft,.wrk_plleft.cta_bxinnerleft{padding:20px 30px 20px 50px;width:50%;}
.cta_bxinnerright{width:50%;text-align: right;display: flex;justify-content: end;align-items: end;height: 100%;margin-bottom: -33px;}
.applications_ctaouter{margin-bottom: 60px;}
.applications_ctaouternopadding{margin-bottom: 0;}
.application_section{padding:60px 0 40px;}
.application_tab_top{display: flex;gap:5px;flex-direction: column;justify-content: center;align-items: center;}
.com_hd{font-weight: 600;font-size: 28px;line-height: 50px;color:#222222}
.com_des{font-weight: 400;font-size: 16px;line-height: 26px;color:#222222;text-align: center;}
.application_tab_mid{display: flex;margin-top:20px;justify-content: center;}
.normaltab_btn.MuiButtonBase-root {background: #DAECFF;border-radius: 100px;display: flex;gap:10px;padding:5px 20px 5px 0;text-transform: capitalize;}
.normaltab_btn.MuiButtonBase-root:before{bottom:-20px;background:#DAECFF;height:4px;border-radius: 4px;content:"";left:0;}
.avator_bx{width:40px;height:40px;border-radius: 100%;background: #002E5D;display: flex;align-items: center;justify-content: center;}
.avator_txt{font-weight: 600;font-size: 18px;line-height: 26px;color:#222;}
.application_tab_midinner{display: flex;gap: 10px;flex-direction: column;}
.activeseltab_btn.MuiButtonBase-root {background: #002E5D;}
.activeseltab_btn .avator_bx{background:#fff;}
.activeseltab_btn .avator_txt{color:#fff;}
.application_tab_midlist{display: flex;gap:10px;}
.application_tabcontentouter{width:100%}
.progressbx { background: #DAECFF; border-radius: 4px; width: 100%; height: 4px; margin-top: 10px; overflow: hidden; }
.progrssinner { background-color: #002E5D; height: 100%; transition: width 0.3s ease-in-out; }
.commonblog_detailstop{padding:20px 0}
.commonblog_detailstop .MuiBreadcrumbs-li a {font-weight: 600;}
.blog-card-content .btn2 {color: rgba(0, 46, 93, 1);font-weight: 400;
  font-size: 14px;line-height: 24px;padding:0;vertical-align: top;
  display: flex;align-items: center;cursor: pointer;}
.blogright_arrow{background: url(../Helper/images/new/slider_arrow.svg) no-repeat;width: 14px;
  height: 12px;display: flex;margin-left: 5px;background-size: 100%;}
  .faq_logsc{position: absolute;top:-70px;right:225px}
.faq_pagination{margin-top:30px;gap:10px;display: flex;}
.faq_pagination .MuiButtonBase-root {padding:0 5px;min-width: inherit; min-width: 30px;height: 30px;border-radius: 100%;}
@media only screen and (max-width:768px) {

.cta_bxinnerright{width:100%;margin-bottom: 0;}
.cta_bxinner{flex-wrap: wrap;}
.cta_bxinnerleft, .home_ctaouter .cta_bxinnerleft,.wrk_plleft.cta_bxinnerleft{width:100%;padding:20px!important}
.blogbannerbg .banner_container1,.case_bannerbg .banner_container1{height:150px}
}
@media only screen and (max-width:640px) {
  .bluebg_banner .inner_banner_text br{display: none;}
}
@media only screen and (max-width:568px) {
  .container {padding: 0 16px;}
  .demo-popup {min-width: 385px;}
  .demo-close-btn {right: -10px;top: -49px;}
  .demo-close-btn > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {color: #fff;}
  .common_innerbannerimg{min-height:250px;height: 250px;}
  .banner_container_mids{text-align: center;}
  .inner_banner_heading{font-size: 26px;line-height: 32px;}
  .bluebg_banner .banner_container_mids{padding-top:20px}
  .bluebg_banner .container{min-height:160px;}
  .wrk_plcontent{font-size: 16px;}
}
@media only screen and (max-width:480px) {
.cta_bxinnerright img {
  max-width: 100%;
  height: auto;
}
.bluebg_banner .inner_banner_text{font-size: 14px;}
}